import React from 'react';
import { useRouter } from 'next/router';
import { BreadcrumbItem, useDisclosure, keyframes } from '@chakra-ui/react';
import { Menu } from './Menu';
import { Application, Instance } from '@types';
import { useDashboardCRUD, useDashboardSWR } from '@hooks/useDashboardAPI';
import { Container } from '@components/header/Breadcrumb';
import { HTTPError } from '@utils';
import {
  CreateProdInstanceModal,
  ProdInstanceForm,
} from '@components/instances';
import { ApplicationSwitcher } from './ApplicationSwitcher';
import {
  useDeployStatusForInstances,
  useInstanceAndApplicationIsValid,
} from '@hooks';
import { colors } from '@theme/foundations';
import { useAnalytics } from '@hooks/useAnalytics';

const initialData: Partial<Application> = {
  instances: [],
};

const pulsingBackground = keyframes`
    0% { background: transparent }
    17% { background: ${colors.primary[200]}}
    34% { background: transparent}
    50% { background: ${colors.primary[200]}}
    66% { background: transparent }
    83% { background: ${colors.primary[200]}}
    100% { background: transparent }
`;

const redirectHighlighting = `${pulsingBackground} 4s linear`;

export function ApplicationInstance(): JSX.Element {
  const { track } = useAnalytics();
  const { hasError } = useInstanceAndApplicationIsValid();
  const { query, push, pathname } = useRouter();
  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
  } = useDisclosure();
  const { applicationId, instanceId } = query;
  const { data: application = initialData, mutate } =
    useDashboardSWR<Application>(`/v1/applications/${applicationId}`);
  const instancesDeployStatus = useDeployStatusForInstances(
    application?.instances || [],
  );

  const { create } = useDashboardCRUD();

  const shouldRenderBreadCrumb = applicationId && instanceId;
  const isLastActiveRedirect = query['last-active-redirect'] === 'true';

  const handleInstanceChange = instanceId => {
    if (!instanceId) {
      onModalOpen();
      return;
    }
    const activeInstance = instancesDeployStatus.find(
      ({ id }) => id === instanceId,
    );
    track('Dashboard_Top Nav_Instance Menu Item Clicked', {
      surface: 'Dashboard',
      location: 'Top Nav',
      clickedOn: activeInstance ? activeInstance.environmentType : '',
    });
    void push({ pathname, query: { ...query, instanceId: instanceId } });
  };

  const handleSave = async (
    data: ProdInstanceForm,
  ): Promise<string | false> => {
    const { clone_instance_id, home_url } = data;

    const homeUrl = `https://${home_url}`;

    try {
      const instance = (await create(
        `/v1/applications/${applicationId}/production_instance`,
        {
          // TODO: when implement integration fix the null hack(backend)
          clone_instance_id:
            clone_instance_id === '' ? null : clone_instance_id,
          home_url: homeUrl,
        },
      )) as Instance;

      await mutate();
      onModalClose();

      if (instance) {
        await push(`/apps/${applicationId}/instances/${instance?.id}`);
      }
      return false;
    } catch (error) {
      if (error.name === 'HTTPError') {
        const httpError: HTTPError = error;
        const fieldErrorName = httpError.getFieldErrorsNames()[0];
        const errorMessage =
          httpError.getFieldErrorByName(fieldErrorName)?.long_message;
        return errorMessage;
      }
      throw error;
    }
  };

  return (
    <>
      {isModalOpen && (
        <CreateProdInstanceModal
          key={application.instances?.length}
          isOpen={isModalOpen}
          onClose={onModalClose}
          onSubmit={handleSave}
          instances={application.instances}
        />
      )}
      <Container>
        {shouldRenderBreadCrumb && !hasError ? (
          <BreadcrumbItem>
            <ApplicationSwitcher
              currentApp={application}
              redirectHighlighting={
                isLastActiveRedirect && redirectHighlighting
              }
            />
          </BreadcrumbItem>
        ) : null}
        {shouldRenderBreadCrumb && !hasError ? (
          <BreadcrumbItem>
            <Menu
              options={instancesDeployStatus}
              handleOnSelect={handleInstanceChange}
              selectedOptionId={instanceId as string}
              redirectHighlighting={
                isLastActiveRedirect && redirectHighlighting
              }
            />
          </BreadcrumbItem>
        ) : null}
      </Container>
    </>
  );
}
