import React from 'react';
import { Box, Center, Image, Input, Switch, VStack } from '@chakra-ui/react';
import {
  useApplication,
  useDashboardCRUD,
  useInstance,
  useSupportedFeature,
} from '@hooks';
import { ActiveDisplayConfig, Application } from '@types';
import {
  CardDualPanel,
  CardWithSwitch,
  IMAGE_UPLOADER_ICON_TYPES,
  IMAGE_UPLOADER_IMAGE_TYPES,
  InputBox,
  PageLoader,
  StickyBottomCTA,
  ImageUploaderBox,
  ImageUploaderPlaceholder,
} from '@components/common';
import { GlobeAltIcon, PhotographIcon } from '@heroicons/react/solid';
import { usePaymentRequired } from '@context/PaymentRequiredContext';
import { useForm } from 'react-hook-form';
import { preventSubmitOnEnter } from '@utils';
import { SUPPORTED_FEATURES } from '@constants';

const DEFAULT_VALUES = {
  name: '',
  card_background_color: '#000000',
};

const FORM_ID = 'general_settings';

export const Branding = (): JSX.Element => {
  const { update, create } = useDashboardCRUD();
  const { application, mutate, error } = useApplication();
  const { instance, mutate: mutateInstance } = useInstance();
  const { showModal: showUpgradePlanModal } = usePaymentRequired();
  const { isSupported, isPremium } = useSupportedFeature();
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });
  const { isDirty, isSubmitting, errors } = formState;
  const displayConfig = instance?.active_display_config;
  const isLoading = !application || !instance;
  const [isUpdatingBranding, setIsUpdatingBranding] = React.useState(false);

  React.useEffect(() => {
    reset(application);
  }, [application]);

  if (isLoading) {
    return <PageLoader />;
  }

  const appPath = `/v1/applications/${application.id}`;
  const appLogoPath = `${appPath}/logo`;
  const appFaviconPath = `${appPath}/favicon`;
  const displayConfigPath = `/v1/instances/${instance.id}/display_config`;
  const enforceClerkBranding = displayConfig?.branded;

  const mutateDisplayConfig = (dc: ActiveDisplayConfig) => {
    const updatedInstances = application.instances.map(inst => {
      return inst.active_display_config.id === displayConfig.id
        ? { ...inst, active_display_config: dc }
        : inst;
    });

    const updatedApp: Application = {
      ...application,
      instances: updatedInstances,
    };
    return mutate(updatedApp, false);
  };

  const updateApp = async (changes: Partial<Application>) => {
    const payload = { ...application, ...changes };
    try {
      const app = await update(appPath, payload);
      void mutate(app as Application, false);
    } catch (_) {
      return;
    }
  };

  const updateAppImage = async (data: FormData, path: string) => {
    try {
      const res = await create(path, data);
      void mutate(
        {
          ...application,
          ...(res as Application),
        },
        false,
      );
    } catch (_) {
      return;
    }
  };

  const updateDisplayConfig = async (changes: Partial<ActiveDisplayConfig>) => {
    const payload = { ...displayConfig, ...changes };
    try {
      const updatedDisplayConfig = await update(displayConfigPath, payload);
      await mutateDisplayConfig(updatedDisplayConfig as ActiveDisplayConfig);
      await mutateInstance();
    } catch (_) {
      setIsUpdatingBranding(false);
      return;
    } finally {
      setIsUpdatingBranding(false);
    }
  };

  const handleUpload = async (file: File, path: string) => {
    const imgData = new FormData();
    imgData.append('file', file);
    return updateAppImage(imgData, path);
  };

  const handleDelete = (imageType: 'logo' | 'favicon') => {
    return updateApp({ [`${imageType}_image_id`]: '' });
  };

  const handleToggleClerkBranding = ev => {
    const feature = SUPPORTED_FEATURES.remove_branding;

    if (!isSupported(feature) && enforceClerkBranding) {
      return showUpgradePlanModal({
        features: [feature],
      });
    }

    if (isPremium(feature) && enforceClerkBranding) {
      showUpgradePlanModal();
    }

    setIsUpdatingBranding(true);
    return updateDisplayConfig({ branded: !ev.target.checked });
  };

  if (error) {
    return (
      <Center h='full' w='full'>
        <p>Something went wrong</p>
      </Center>
    );
  }

  const logoURL = application.logo_image_url;
  const faviconURL = application.favicon_image_url;

  return (
    <>
      <form
        id={FORM_ID}
        onSubmit={handleSubmit(updateApp)}
        onKeyDown={preventSubmitOnEnter}
      >
        <VStack spacing='7' align='stretch' mb='7'>
          <CardDualPanel
            title='Branding'
            subtitle="Apply your application's brand to Clerk components"
          >
            <InputBox
              leftIcon={GlobeAltIcon}
              label='Application name'
              error={errors.name}
            >
              <Input
                {...register('name', {
                  required: 'Application name is required',
                })}
              />
            </InputBox>

            <ImageUploaderBox
              leftIcon={PhotographIcon}
              label='Logo'
              handleDelete={logoURL ? () => handleDelete('logo') : null}
              handleUpload={f => {
                return handleUpload(f, appLogoPath);
              }}
            >
              {logoURL ? (
                <Image src={logoURL} height='48px' borderRadius='base' />
              ) : (
                <ImageUploaderPlaceholder size='lg' />
              )}
            </ImageUploaderBox>

            <ImageUploaderBox
              leftIcon={PhotographIcon}
              label='Icon'
              handleUpload={f => {
                return handleUpload(f, appFaviconPath);
              }}
              acceptedTypes={IMAGE_UPLOADER_IMAGE_TYPES.concat(
                IMAGE_UPLOADER_ICON_TYPES,
              )}
            >
              {faviconURL ? (
                <Image src={faviconURL} height='32px' borderRadius='base' />
              ) : (
                <ImageUploaderPlaceholder size='sm' />
              )}
            </ImageUploaderBox>
          </CardDualPanel>

          {/* TODO: uncomment once the DAPI supports these */}
          {/* <CardDualPanel
            title='Support'
            subtitle="Indicate your application's support channels to display on Clerk components"
          >
            <InputBox leftIcon={MailIcon} label='Support email'>
              <Input placeholder='todo@example.com' />
            </InputBox>

            <InputBox leftIcon={LinkIcon} label='Support link'>
              <Input placeholder='example.com/todo' />
            </InputBox>

            <InputBox leftIcon={LinkIcon} label='Terms of Service link'>
              <Input placeholder='example.com/todo' />
            </InputBox>

            <InputBox leftIcon={LinkIcon} label='Privacy Policy link'>
              <Input placeholder='example.com/todo' />
            </InputBox>
          </CardDualPanel> */}

          <StickyBottomCTA
            formId={FORM_ID}
            isVisible={isDirty}
            onReset={() => reset(application)}
            isSubmitting={isSubmitting}
          />
        </VStack>
      </form>
      <CardWithSwitch
        title='Hide Clerk branding'
        subtitle='Remove “Secured by Clerk” branding on Clerk Components'
        showPremiumBadge={isPremium(SUPPORTED_FEATURES.remove_branding)}
      >
        <Box w='48px' h='48px'>
          <Switch
            onChange={handleToggleClerkBranding as any}
            isChecked={!enforceClerkBranding}
            isDisabled={isUpdatingBranding}
          />
        </Box>
      </CardWithSwitch>
    </>
  );
};
