import React from 'react';
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { useTLDParser } from '@hooks/useTLDParser';
import { useFormContext } from 'react-hook-form';
import type { ProdInstanceForm } from './CreateProdInstanceModal';
import { InputBox } from '@components/common';

type UrlInputProps = {
  onBlur?: () => void;
  autoFocus?: boolean;
};

export function UrlInput({
  onBlur,
  autoFocus = true,
}: UrlInputProps): JSX.Element {
  const {
    register,
    formState: { errors },
  } = useFormContext<ProdInstanceForm>();
  const { parse } = useTLDParser();

  const validateUrl = (value: string): boolean | string => {
    if (!value) {
      return 'Enter a valid Application domain';
    }

    const tldResult = parse(value);

    if (!tldResult.domain) {
      return 'Enter a valid Application domain';
    }

    return true;
  };

  return (
    <InputBox
      p={0}
      mb={4}
      width='100%'
      label='Application domain'
      description='Enter the full application domain where your application lives. Include the subdomain, if applicable.'
      error={errors?.home_url}
    >
      <InputGroup>
        <InputLeftAddon>https://</InputLeftAddon>
        <Input
          autoFocus={autoFocus}
          name='home_url'
          id='home_url'
          type='text'
          placeholder='e.g. example.com'
          defaultValue=''
          {...register('home_url', {
            required: 'Application domain is required',
            validate: validateUrl,
            onBlur,
          })}
          outline='none !important'
          boxShadow='none !important'
          _active={{ outline: '0', boxShadow: 'none !important' }}
        />
      </InputGroup>
    </InputBox>
  );
}
