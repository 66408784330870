import React from 'react';
import { HTTPError } from '@utils';
import { useForm } from 'react-hook-form';
import { SamlConnection } from '@types';
import { Input, HStack, FormControl } from '@chakra-ui/react';
import { useLocation, useToast } from '@hooks';
import { InputBox, Modal } from '@components/common';
import { useCreateSamlConnection } from '@components/samlConnections/api';

const FORM_ID = 'enterprise_connection_create';

type CreateSamlConnectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function CreateSamlConnectionModal({
  isOpen,
  onClose,
}: CreateSamlConnectionModalProps): JSX.Element {
  const { applicationId, instanceId, push } = useLocation();
  const { showErrorToast } = useToast();
  const { createSamlConnection } = useCreateSamlConnection();
  const formMethods = useForm<SamlConnection>({ mode: 'onChange' });
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
    setError,
  } = formMethods;

  const onCloseModal = () => {
    reset();
    onClose();
  };

  const handleCreateSamlConnection = async data => {
    try {
      const { id } = await createSamlConnection(data);
      void push(
        `/apps/${applicationId}/instances/${instanceId}/user-authentication/enterprise-connections/${id}`,
      );
    } catch (err) {
      if (err.name === 'HTTPError' && err.code === 422) {
        (err as HTTPError)?.fieldErrors.forEach(err => {
          setError(err?.meta?.param_name as keyof SamlConnection, {
            message: err.message,
          });
        });
      } else if (err.name === 'HTTPError' && err.code !== 500) {
        const error = (err as HTTPError)?.globalErrors[0];
        showErrorToast(
          error.long_message || 'Something went wrong, please try again',
        );
      } else {
        showErrorToast('Something went wrong, please try again');
      }
    }
  };

  return (
    <Modal
      size='md'
      closeOnEsc
      autoFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      title='Create connection'
      subtitle='Specify the basic details of this connection. Detailed configuration will come after this step.'
      closeOnOverlayClick={false}
    >
      <Modal.Body>
        <form
          autoComplete='off'
          id={FORM_ID}
          onSubmit={handleSubmit(handleCreateSamlConnection)}
        >
          <HStack
            flexDirection='column'
            alignItems='initial'
            spacing={0}
            gap={5}
          >
            <InputBox label='Name' error={errors.name} p={0} mb={0}>
              <FormControl isInvalid={!!errors.name}>
                <Input
                  placeholder='My connection'
                  isDisabled={isSubmitting}
                  {...register('name', {
                    required: 'Name is required',
                  })}
                />
              </FormControl>
            </InputBox>

            <InputBox label='Domain' error={errors.domain} p={0} mb={0}>
              <FormControl isInvalid={!!errors.domain}>
                <Input
                  placeholder='example.com'
                  isDisabled={isSubmitting}
                  {...register('domain', {
                    required: 'Domain is required',
                  })}
                />
              </FormControl>
            </InputBox>
          </HStack>
        </form>
      </Modal.Body>
      <Modal.ActionFooter
        rightButtonRestProps={{ form: FORM_ID, type: 'submit' }}
        isRightButtonLoading={isSubmitting}
        isRightButtonDisabled={isSubmitting}
        leftButtonMessage='cancel'
        onLeftButtonClicked={onCloseModal}
        rightButtonMessage='Create'
      />
    </Modal>
  );
}
