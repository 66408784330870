import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { InputBox } from '@components/common';

type InputBoxPreview = {
  domain: string;
  subdomain: string;
  homeUrl: string;
};

export function InputBoxPreview({
  domain,
  subdomain,
  homeUrl,
}: InputBoxPreview): JSX.Element {
  return (
    <InputBox
      p={0}
      mb={2}
      width='100%'
      label='Subdomain'
      description='This is used to form the basis for your application redirects. You can set or modify this in the Paths page later.'
    >
      <Box
        boxShadow='none'
        bg='gray.100'
        borderWidth={1}
        borderColor='gray.200'
        borderRadius={6}
        fontSize='xs'
        px={3.5}
        py={2.5}
        height={10}
      >
        {subdomain && homeUrl ? (
          <>
            <Text as='span' color='gray.800'>
              {subdomain}
            </Text>
            <Text as='span' color='gray.400'>{`.${domain}`}</Text>
          </>
        ) : (
          ''
        )}
      </Box>
    </InputBox>
  );
}
