import React from 'react';
import { CardDualPanel, CopyTextInput } from '@components/common';
import { SamlConnection } from '@types';

type SamlConnectionSPDetailsProps = {
  samlConnection: SamlConnection;
};
export function SamlConnectionSPDetails({
  samlConnection,
}: SamlConnectionSPDetailsProps): JSX.Element {
  return (
    <CardDualPanel
      title='Service provider details'
      subtitle='Enter the following values into your identity provider to initiate the connection.'
    >
      <CopyTextInput
        label='Assertion consumer service URL'
        valueToCopy={samlConnection.acs_url}
        pt={0}
        pb={0}
      />

      <CopyTextInput
        label='Service provider entity ID'
        valueToCopy={samlConnection.sp_entity_id}
        pb={0}
      />
    </CardDualPanel>
  );
}
