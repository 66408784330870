import React from 'react';
import { useUser } from '@clerk/nextjs';
import { CardInfoPanel, HelperLink } from '@components/common';
import { Box, Text } from '@chakra-ui/react';
import { useLocation } from '@hooks';
import { ENTERPRISE_CONNECTIONS_GUIDE_URL } from '../constants';

export function CompleteConnectionNotice(): JSX.Element | null {
  const { user } = useUser();
  const { query } = useLocation();
  const { samlConnectionId } = query as Record<string, string | undefined>;
  const key = `${samlConnectionId}_complete`;

  if (user.unsafeMetadata?.[key]) {
    return null;
  }

  const onDismiss = async () => {
    return await user.update({
      unsafeMetadata: {
        ...user.unsafeMetadata,
        [key]: true,
      },
    });
  };

  return (
    <CardInfoPanel
      title='You’re not quite done!'
      titleContainerStyles={{ mb: 2 }}
      onDismiss={onDismiss}
    >
      <Box>
        <Text textStyle='sm-normal' color='gray.500' mb={7}>
          This connection needs to be enabled first, before users can start
          authenticating with it.
        </Text>

        <HelperLink display='block' href={ENTERPRISE_CONNECTIONS_GUIDE_URL}>
          Read enterprise connections guide
        </HelperLink>
      </Box>
    </CardInfoPanel>
  );
}
