import React from 'react';
import { Stack } from '@chakra-ui/layout';
import { useInstance, useToast } from '@hooks';
import { CardWithSwitch, InformationBox } from '@components/common';
import { ConfirmationModal } from '@components/modal';
import { Box, Switch, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { CompleteConnectionNotice } from '@components/samlConnections';
import { UpdateSamlConnectionProps } from '@components/samlConnections/api';
import { HTTPError, isProduction } from '@utils';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { SamlConnection } from '@types';

type SamlConnectionEnableProps = {
  samlConnection: SamlConnection;
  updateSamlConnection: (
    id: string,
    data: UpdateSamlConnectionProps,
  ) => Promise<SamlConnection>;
  mutateSamlConnection: () => void;
};

export const SamlConnectionEnable = React.forwardRef(
  (
    {
      samlConnection,
      updateSamlConnection,
      mutateSamlConnection,
    }: SamlConnectionEnableProps,
    ref: React.Ref<HTMLDivElement>,
  ): JSX.Element => {
    const { instance, isValidating: isValidatingInstance } = useInstance();
    const { showSuccessToast, showErrorToast } = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isChecked = samlConnection.active;

    const handleChange = e => {
      const isChecked = e.target.checked;
      const showBillingConfirmation =
        instance && isProduction(instance.environment_type) && isChecked;

      // If connection is being deactivated or activated for prod instances, ask user to confirm
      if (!isChecked || showBillingConfirmation) {
        onOpen();
      } else {
        return handleUpdate({ active: isChecked });
      }
    };

    const handleConfirmation = async () => {
      await handleUpdate({ active: !isChecked });
      onClose();
    };

    const handleUpdate = async ({ active }: { active: boolean }) => {
      try {
        await updateSamlConnection(samlConnection.id, { active });
        showSuccessToast(
          isChecked ? 'Connection disabled' : 'Connection enabled',
        );
        void mutateSamlConnection();
      } catch (err) {
        if (err.name === 'HTTPError' && err.code !== 500) {
          const error = (err as HTTPError)?.globalErrors[0];
          showErrorToast(
            error.long_message ||
              'Something went wrong, please try again later.',
          );
        } else {
          showErrorToast('Something went wrong, please try again later.');
        }
      }
    };

    if (isValidatingInstance) {
      return null;
    }

    return (
      <Stack spacing='8' ref={ref}>
        <CompleteConnectionNotice />

        <CardWithSwitch
          title='Enable connection'
          subtitle='By enabling this connection users will be able to authenticate using this method.'
        >
          <Box w='48px' h='48px'>
            <Switch
              isChecked={isChecked}
              onChange={handleChange}
              aria-label='Enable connection'
            />
          </Box>
        </CardWithSwitch>

        <ConfirmationModal
          isOpen={isOpen}
          handleCancelation={onClose}
          handleConfirmation={handleConfirmation}
          confirmationMessage={
            isChecked ? 'Disable connection' : 'Enable connection'
          }
          colorScheme={isChecked ? 'red' : 'primary'}
          cancelationMessage='Cancel'
          message={
            isChecked ? (
              <>
                <Text textStyle='md-normal'>
                  Are you sure you want to disable{' '}
                  <Text as='span' textStyle='md-semibold'>
                    {samlConnection.name}
                  </Text>
                  ?
                </Text>
                <Text textStyle='md-normal'>
                  Users will no longer be able to use this enterprise connection
                  to sign in. Any features that rely on this connection may
                  cease to function.
                </Text>
              </>
            ) : (
              <InformationBox
                icon={ExclamationCircleIcon}
                iconColor='warning.300'
              >
                <VStack align='start' spacing='1'>
                  <Text textStyle='md-semibold'>
                    By enabling this enterprise connection, an additional charge
                    of $50 will be added to this month's invoice.
                  </Text>
                  <Text textStyle='md-normal'>
                    Are you sure you want to proceed?
                  </Text>
                </VStack>
              </InformationBox>
            )
          }
          headerTitle={isChecked ? 'Disable connection' : 'Confirmation'}
        />
      </Stack>
    );
  },
);

SamlConnectionEnable.displayName = 'SamlConnectionEnable';
