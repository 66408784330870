import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { useLocation } from '@hooks';
import { CardContainer } from '@components/common';
import NextLink from 'next/link';

export function EmailAddressNotEnabledCard(): JSX.Element {
  const { applicationId, instanceId } = useLocation();

  return (
    <CardContainer spacing={6}>
      <Flex justifyContent='center'>
        <Box>
          <Text textStyle='sm-medium' color='gray.500' mb={4}>
            Email address needs to be enabled before creating Enterprise
            Connections
          </Text>

          <NextLink
            href={`/apps/${applicationId}/instances/${instanceId}/user-authentication/email-phone-username`}
          >
            <Flex justifyContent='center'>
              <Box as='span' textStyle='sm-medium' color='primary.500'>
                Turn on Email address to get started
              </Box>
            </Flex>
          </NextLink>
        </Box>
      </Flex>
    </CardContainer>
  );
}
