import React from 'react';
import { Input, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { CardDualPanel, InputBox, StickyBottomCTA } from '@components/common';
import { FormProvider, useForm } from 'react-hook-form';
import { CertificateUploaderBox } from './CertificateUploaderBox';
import { SamlConnectionSPDetails } from './SamlConnectionSPDetails';
import { HTTPError } from '@utils';
import { SamlConnection } from '@types';
import { useToast } from '@hooks';
import { ConfirmationModal } from '@components/modal';
import { UpdateSamlConnectionProps } from '@components/samlConnections/api';

const FORM_ID = 'enterprise_connection';

type SamlConnectionFormProps = {
  samlConnection: SamlConnection;
  updateSamlConnection: (
    id: string,
    data: UpdateSamlConnectionProps,
  ) => Promise<SamlConnection>;
  mutateSamlConnection: () => void;
};

export function SamlConnectionForm({
  samlConnection,
  updateSamlConnection,
  mutateSamlConnection,
}: SamlConnectionFormProps): JSX.Element {
  const { showErrorToast, showSuccessToast } = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const formMethods = useForm<SamlConnection>({ mode: 'onChange' });

  const {
    handleSubmit,
    setError,
    formState: { isDirty, isSubmitting, errors },
    reset,
    register,
  } = formMethods;

  React.useEffect(() => {
    if (!samlConnection) {
      return;
    }

    reset(samlConnection);
  }, [samlConnection, reset]);

  const onSubmit = async data => {
    const { domain } = data;

    // If the domain has changed, ask user to confirm
    if (domain !== samlConnection.domain) {
      onOpen();
    } else {
      return handleUpdate(data);
    }
  };

  const handleUpdate = async data => {
    const { name, domain, idp_sso_url, idp_entity_id, idp_certificate } = data;

    try {
      await updateSamlConnection(samlConnection.id, {
        name,
        domain,
        idp_sso_url,
        idp_entity_id,
        idp_certificate,
      });
      showSuccessToast('Connection settings saved.');
      void mutateSamlConnection();
    } catch (err) {
      if (err.name === 'HTTPError' && err.code === 422) {
        (err as HTTPError)?.fieldErrors.forEach(err => {
          setError(err?.meta?.param_name as keyof SamlConnection, {
            message: err.message,
          });
        });
      } else {
        showErrorToast('Something went wrong, please try again later');
      }
    }
  };

  const handleConfirmation = async data => {
    await handleUpdate(data);
    onClose();
  };

  const handleCancellation = async () => {
    onClose();
    reset();
  };

  return (
    <FormProvider {...formMethods}>
      <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing='7' align='stretch' mb='7'>
          <CardDualPanel
            title='Connection details'
            subtitle='Specify the name and domain for this connection.'
          >
            <InputBox label='Name' error={errors.name} pt={0} pb={0}>
              <Input
                placeholder='My connection'
                isDisabled={isSubmitting}
                {...register('name', {
                  required: 'Name is required',
                })}
              />
            </InputBox>

            <InputBox label='Domain' error={errors.domain} pb={0}>
              <Input
                placeholder='example.com'
                isDisabled={isSubmitting}
                {...register('domain', {
                  required: 'Domain is required',
                })}
              />
            </InputBox>
          </CardDualPanel>

          <SamlConnectionSPDetails samlConnection={samlConnection} />

          <CardDualPanel
            title='Identity provider configuration'
            subtitle='Retrieve these values from your identity provider and enter them in this section to establish connection.'
          >
            <InputBox
              label='IdP SSO URL'
              error={errors.idp_sso_url}
              pt={0}
              pb={0}
            >
              <Input
                isDisabled={isSubmitting}
                placeholder='https://example.com/saml/sso'
                {...register('idp_sso_url', {
                  required: 'IdP SSO URL is required and must be a valid URL',
                })}
              />
            </InputBox>

            <InputBox label='IdP Entity ID' error={errors.idp_entity_id} pb={0}>
              <Input
                placeholder='https://example.com/saml/idp'
                isDisabled={isSubmitting}
                {...register('idp_entity_id', {
                  required: 'IdP Entity ID is required',
                })}
              />
            </InputBox>

            <InputBox label='Certificate' error={errors.idp_certificate} pb={0}>
              <CertificateUploaderBox />
            </InputBox>
          </CardDualPanel>

          <ConfirmationModal
            isOpen={isOpen}
            handleCancelation={handleCancellation}
            handleConfirmation={handleSubmit(handleConfirmation)}
            confirmationMessage='Apply changes'
            cancelationMessage='Cancel'
            message={
              <>
                <Text textStyle='md-normal'>
                  A domain change has been detected.
                </Text>
                <Text textStyle='md-normal'>
                  If you proceed with saving your changes it is possible that
                  the existing users of your connection will not be able to sign
                  in. Please make sure that the new domain value is the intended
                  one.
                </Text>
              </>
            }
            headerTitle='Domain changed'
          />

          <StickyBottomCTA
            formId={FORM_ID}
            isVisible={isDirty}
            onReset={() => reset()}
            isSubmitting={isSubmitting}
          />
        </VStack>
      </form>
    </FormProvider>
  );
}
