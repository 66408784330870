import React from 'react';
import { Box } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import type { ProdInstanceForm } from './CreateProdInstanceModal';
import { useTLDParser } from '@hooks';
import { InputPreview } from '@components/common/InputPreview';
import { InputBoxPreview } from './InputBoxPreview';

export function PreviewInstanceDetails(): JSX.Element {
  const { parse } = useTLDParser();
  const { watch } = useFormContext<ProdInstanceForm>();
  const { home_url } = watch();

  const tldResult = parse(home_url);

  return (
    <Box bg='gray.50' p={4} borderRadius={8}>
      <InputPreview
        label='Root domain'
        description='This forms the basis for your DNS records, your Frontend API URL and is used by Clerk to set cookies for your application.'
        info={`Please ensure you have access to set the necessary CNAME records for this root domain.
              For example, preview deployment URLs using vercel.app will not work.`}
        value={
          tldResult && home_url
            ? tldResult.domain
              ? tldResult.domain
              : tldResult.hostname
            : ''
        }
        disableCopy
        disableExternalLink
        color='gray.800'
      />
      <InputBoxPreview
        domain={tldResult.domain}
        subdomain={tldResult.subdomain}
        homeUrl={home_url}
      />
    </Box>
  );
}
