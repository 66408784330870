import React, { lazy, Suspense } from 'react';
import { Button, Flex, VStack } from '@chakra-ui/react';
import { Modal } from '@components/common';
import { useFormContext } from 'react-hook-form';
import { Heading } from '@components/common';
import { useInstance, useSupportedFeature } from '@hooks';
import { SUPPORTED_FEATURES } from '@constants';
import { usePaymentRequired } from '@context/PaymentRequiredContext';
import { PasswordMeterOptions } from './PasswordMeter';
import { PasswordLengthLimit } from './PasswordLengthLimit';
import { FormCheckbox, Checkbox } from '@components/common/Checkbox';

const PasswordEndpointNoticeInfoBox = lazy(() =>
  import('./PasswordEndpointNoticeInfoBox').then(m => ({
    default: m.PasswordEndpointNoticeInfoBox,
  })),
);

type PasswordConfigModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const minLength = 8;
const maxLength = 72;

export function PasswordConfigModal({
  isOpen,
  onClose,
}: PasswordConfigModalProps): JSX.Element {
  const { watch, getValues } = useFormContext();
  const specialChar = '\\ !"#$%&\'()*+,-./:;<=>?@[\\]^_`\\{|}~';
  const passwordSettings = watch('password_settings');
  const { isSupported, isPremium, isUnsupportedAndEnabled } =
    useSupportedFeature();
  const { showModal } = usePaymentRequired();
  const { instance } = useInstance();

  const passwordComplexityFeature = SUPPORTED_FEATURES.password_complexity;

  const passwordComplexityBillingProps = (name: string) => {
    return {
      showTag: isPremium(passwordComplexityFeature),
      unsupportedFeatureAction: showBillingModal,
      isSupportedFeature: handleFeature(passwordComplexityFeature, name),
    };
  };

  const handleFeature = (featureName: string, currentValue: string) => {
    return (
      isSupported(featureName) ||
      isUnsupportedAndEnabled({
        feature: featureName,
        currentValue: getValues(currentValue),
      })
    );
  };

  const showBillingModal = () => {
    return showModal({
      features: [passwordComplexityFeature],
    });
  };

  const showDeprecationPasswordWidget =
    instance?.patch_me_password_state === 'enabled';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      size='2xl'
      title='Password policies'
      subtitle='Configure strength and complexity requirements for user passwords'
    >
      <Modal.Body>
        <Suspense>
          {showDeprecationPasswordWidget && <PasswordEndpointNoticeInfoBox />}
        </Suspense>

        <Heading as='h2' size='sm'>
          Rules
        </Heading>
        <VStack align='flex-start' w='full' py='4' pl={0} spacing='4'>
          <Checkbox
            isChecked={true}
            pointerEvents='none'
            onChange={() => null}
            name='password_settings.min_length'
            label='Enforce minimum password length'
            description={`Passwords must contain ${minLength} or more characters. Select a higher value to increase the minimum length, up to ${maxLength} characters.`}
          />
          <Flex alignItems='center' w='200px' minH={8} pl={9} mt={2}>
            <PasswordLengthLimit
              min={minLength}
              max={maxLength}
              defaultValue={minLength}
              passwordMinLength={passwordSettings.min_length}
            />
          </Flex>

          <FormCheckbox
            name='password_settings.disable_hibp'
            label='Reject compromised passwords, powered by HaveIBeenPwned'
            subtitleLink='https://clerk.com/docs/security/password-protection'
          />

          <FormCheckbox
            name='password_settings.show_zxcvbn'
            label='Enforce minimum password strength, powered by zxcvbn'
          />

          <PasswordMeterOptions
            templateColumns='repeat(3, 1fr)'
            width='calc(100% - 60px)'
            ml='35px!important'
            gap={2}
            hasCheckedPasswordStrength={passwordSettings?.show_zxcvbn}
            learnMoreLink='https://clerk.com/docs/security/password-protection'
          />

          <FormCheckbox
            name='password_settings.require_lowercase'
            label='Require at least 1 lowercase character'
            {...passwordComplexityBillingProps(
              'password_settings.require_lowercase',
            )}
          />

          <FormCheckbox
            name='password_settings.require_uppercase'
            label='Require at least 1 uppercase character'
            {...passwordComplexityBillingProps(
              'password_settings.require_uppercase',
            )}
          />

          <FormCheckbox
            name='password_settings.require_numbers'
            label='Require at least 1 number'
            {...passwordComplexityBillingProps(
              'password_settings.require_numbers',
            )}
          />

          <FormCheckbox
            name='password_settings.require_special_char'
            label='Require at least 1 special character'
            description={`Special characters include: ${specialChar}`}
            {...passwordComplexityBillingProps(
              'password_settings.require_special_char',
            )}
          />
        </VStack>
      </Modal.Body>

      <Modal.Footer mt='6'>
        <Flex direction='row' w='full'>
          <Flex justify='space-between' w='full' flexDir='row-reverse'>
            <Button onClick={onClose}>Continue</Button>
          </Flex>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
