import React, { useLayoutEffect, useRef } from 'react';
import { Stack } from '@chakra-ui/layout';
import { SamlConnectionEnable } from './SamlConnectionEnable';
import { SamlConnectionForm } from '@components/samlConnections';
import { Heading } from '@components/common';
import { Center, Spinner, Text } from '@chakra-ui/react';
import { ErrorLayout } from '@components/layouts';
import {
  useGetSamlConnection,
  useUpdateSamlConnection,
} from '@components/samlConnections/api';
import { useLocation } from '@hooks';

export function SamlConnectionSettings(): JSX.Element {
  const { query } = useLocation();
  const { samlConnectionId } = query as Record<string, string | undefined>;
  const { updateSamlConnection } = useUpdateSamlConnection();
  const afterEnableScrollRef = useRef<HTMLDivElement>(null);

  const {
    data: samlConnection,
    isLoading,
    error: samlError,
    mutate: mutateSamlConnection,
  } = useGetSamlConnection(samlConnectionId);

  // A connection can be enabled only if every IdP data has been provided
  const canBeEnabled =
    samlConnection.idp_sso_url &&
    samlConnection.idp_entity_id &&
    samlConnection.idp_certificate;

  useLayoutEffect(() => {
    if (!canBeEnabled || !afterEnableScrollRef) {
      return;
    }
    afterEnableScrollRef.current?.scrollIntoView({ block: 'center' });
  }, [canBeEnabled, afterEnableScrollRef]);

  if (isLoading) {
    return (
      <Center w='full' h='full'>
        <Spinner />
      </Center>
    );
  }

  if (samlError) {
    return (
      <ErrorLayout>
        <Heading as='h1' size='2xl' mb={2}>
          404
        </Heading>
        <Text mb={8}>The page you are looking for could not be found.</Text>
      </ErrorLayout>
    );
  }

  return (
    <Stack spacing='8'>
      {canBeEnabled && (
        <SamlConnectionEnable
          ref={afterEnableScrollRef}
          samlConnection={samlConnection}
          updateSamlConnection={updateSamlConnection}
          mutateSamlConnection={mutateSamlConnection}
        />
      )}

      <SamlConnectionForm
        samlConnection={samlConnection}
        updateSamlConnection={updateSamlConnection}
        mutateSamlConnection={mutateSamlConnection}
      />
    </Stack>
  );
}
