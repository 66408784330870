import React from 'react';
import { Text, Link } from '@chakra-ui/react';
import { CardDualPanel } from '@components/common';
import { RegisteredSwitchInfoField as SwitchInfoField } from '@components/common/Switch';
import { useFeatureFlags } from '@hooks';
export const OrganizationPermissions = (): JSX.Element => {
  const {
    featureFlags: { allow_org_admin_delete },
    isLoading: isFeatureFlagsLoading,
  } = useFeatureFlags();

  if (isFeatureFlagsLoading || !allow_org_admin_delete) {
    return <></>;
  }

  return (
    <CardDualPanel
      title='Default organization permissions'
      subtitle={
        <>
          <Text>
            Define the default settings for newly-created organizations. These
            values can be overridden on a per-organization basis in the
            individual organization’s profile.
          </Text>
          <Text mt={3}>
            Changing this setting will not impact previously created
            organizations.
          </Text>
          <Text>
            <Link
              isExternal
              textStyle='sm-normal'
              color='primary.500'
              href='https://clerk.com/docs/organizations/overview#roles'
              fontWeight='500'
            >
              Learn more about organization permissions
            </Link>
          </Text>
        </>
      }
    >
      <SwitchInfoField
        description=''
        title='Allow organization admins to delete their organizations'
        name='admin_delete_enabled'
        aria-label='Allow organization admins to delete their organizations'
      />
    </CardDualPanel>
  );
};
